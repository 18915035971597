import crypto from 'crypto';

export const sha256Hash = (str: string): string =>
	crypto.createHash('sha256').update(str).digest('hex');

export const extractUrlHostname = (sourceUrl: string) => {
	let url;
	try {
		url = new URL(sourceUrl);
	} catch {
		return undefined;
	}
	return url.hostname;
};

import { fg } from '@atlaskit/platform-feature-flags';
import { type RequestServiceOptions, utils } from '@atlaskit/util-service-support';

import { fetchAggResponseWithCache } from './fetch-agg-response-with-cache';
import type { AggRequest, AggResponse } from './types';

const DEFAULT_GRAPHQL_ENDPOINT = '/gateway/api/graphql';

export async function fetchAggResponse<Variables, Data>({
	graphQuery,
	variables,
	operationName,
	aggAbsoluteUrl: url = DEFAULT_GRAPHQL_ENDPOINT,
	cache,
}: AggRequest<Variables>) {
	const options: RequestServiceOptions = {
		requestInit: {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				operationName,
				query: graphQuery,
				variables,
			}),
		},
		queryParams: {
			...(operationName && { q: operationName }),
		},
	};

	// if there's a ttl use fetch with in-mem cacheing
	if (cache && fg('kd_fe_enable_api_cache')) {
		return fetchAggResponseWithCache<Variables, Data>({
			graphQuery,
			variables,
			operationName,
			cache,
		});
	}

	return utils.requestService<AggResponse<Data>>({ url }, options);
}

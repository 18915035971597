const DEFAULT_TTL = 5 * 60 * 1000;

type FetchResponse = any;
type InflightRequest = Promise<any>;

type CachedItem = {
	ttl: number;
	response: FetchResponse;
};

type setCacheDataProps = {
	key: string;
	response: FetchResponse;
	ttl?: number;
};

export abstract class InMemCache {
	private static cache = new Map<string, CachedItem>();
	private static inFlightRequests = new Map<string, InflightRequest>();

	public static setData({ key, response, ttl }: setCacheDataProps) {
		this.cache.set(key, {
			ttl: Date.now() + (ttl || DEFAULT_TTL),
			response,
		});
	}

	public static getData(key: string) {
		return this.cache.get(key)?.response;
	}

	public static isValid(key: string) {
		const item = this.cache.get(key);
		return Boolean(item) && Date.now() < (item?.ttl || 0);
	}

	public static getInflightRequest(key: string) {
		return this.inFlightRequests.get(key);
	}

	public static setInflightRequest({ key, request }: { key: string; request: InflightRequest }) {
		this.inFlightRequests.set(key, request);
	}

	public static removeInflightRequest(key: string) {
		return this.inFlightRequests.delete(key);
	}

	// only for tests
	public static clearCache() {
		this.cache.clear();
		this.inFlightRequests.clear();
	}
}

import { type RequestServiceOptions, utils } from '@atlaskit/util-service-support';

import { InMemCache } from './in-mem-cache';
import type { AggRequestWithCache, AggResponse } from './types';

const DEFAULT_GRAPHQL_ENDPOINT = '/gateway/api/graphql';

export async function fetchAggResponseWithCache<Variables, Data>({
	graphQuery,
	variables,
	operationName,
	aggAbsoluteUrl: url = DEFAULT_GRAPHQL_ENDPOINT,
	cache,
}: AggRequestWithCache<Variables>): Promise<AggResponse<Data>> {
	const options: RequestServiceOptions = {
		requestInit: {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				operationName,
				query: graphQuery,
				variables,
			}),
		},
		queryParams: {
			...(operationName && { q: operationName }),
		},
	};

	let cachedResponse = InMemCache.getData(cache.key);
	if (cachedResponse && InMemCache.isValid(cache.key)) {
		return Promise.resolve(cachedResponse);
	}

	// return a pending promise that was cached earlier
	const inflightRequest = InMemCache.getInflightRequest(cache.key);
	if (inflightRequest) {
		return inflightRequest;
	}

	// cache miss, fetch data from agg and store ref to promise
	const request: Promise<AggResponse<Data>> = new Promise(async (resolve, reject) => {
		try {
			const response = await utils.requestService<AggResponse<Data>>({ url }, options);
			InMemCache.setData({ key: cache.key, response, ttl: cache.ttl });
			resolve(response);
		} catch (error) {
			reject(error);
		} finally {
			InMemCache.removeInflightRequest(cache.key);
		}
	});

	InMemCache.setInflightRequest({ key: cache.key, request });
	return request;
}

import { type MouseEvent as ReactMouseEvent, useEffect, useState } from 'react';

type useTextHighlighterClickNativeArgs = {
	singleClickCallback: (e: MouseEvent | ReactMouseEvent<HTMLElement, MouseEvent>) => void;
	doubleClickCallback: (e: MouseEvent | ReactMouseEvent<HTMLElement, MouseEvent>) => void;
	delay?: number;
};

// Hook to manage single and double click events reliably
// uses native js event detail
export const useTextHighlighterClickNative = ({
	singleClickCallback,
	doubleClickCallback,
	delay = 250,
}: useTextHighlighterClickNativeArgs) => {
	const [state, setState] = useState<{
		e: MouseEvent | ReactMouseEvent<HTMLElement, MouseEvent> | null;
	}>({
		e: null,
	});

	useEffect(() => {
		if (!Boolean(state.e)) {
			return;
		}

		const timer = setTimeout(() => {
			// single click
			if (state.e?.detail === 1) {
				singleClickCallback(state.e!);
			} else if (state.e?.detail && state.e.detail > 1) {
				doubleClickCallback(state.e!);
			}
			setState({ e: null });
		}, delay);

		return () => clearTimeout(timer);
	}, [state, singleClickCallback, doubleClickCallback, delay]);

	return (e: MouseEvent | ReactMouseEvent<HTMLElement, MouseEvent>) => {
		setState({ e });
	};
};

type useTextHighlighterClickArgs = {
	singleClickCallback: (e: ReactMouseEvent) => void;
	doubleClickCallback: (e: ReactMouseEvent) => void;
	delay?: number;
};

/**
 * @deprecated Use `useTextHighlighterClickNative` instead
 */
// Hook to manage single and double click events reliably
export const useTextHighlighterClick = ({
	singleClickCallback,
	doubleClickCallback,
	delay = 250,
}: useTextHighlighterClickArgs) => {
	const [state, setState] = useState<{ click: number; e: ReactMouseEvent | null }>({
		click: 0,
		e: null,
	});

	useEffect(() => {
		if (!Boolean(state.e)) {
			return;
		}

		const timer = setTimeout(() => {
			// simple click
			if (state.click === 1) {
				singleClickCallback(state.e!);
			}
			setState({ e: null, click: 0 });
		}, delay);

		// the duration between this click and the previous one
		// is less than the value of delay = double-click
		if (state.click === 2) {
			doubleClickCallback(state.e!);
		}

		return () => clearTimeout(timer);
	}, [state, singleClickCallback, doubleClickCallback, delay]);

	return (e: ReactMouseEvent) => {
		setState({ click: state.click + 1, e });
	};
};

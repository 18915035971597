import React, { memo, useEffect } from 'react';

import type { InlineCommentViewComponentProps } from '@atlaskit/editor-common/types';

import { useCommentsContentState, useCommentsContentActions } from '@confluence/comment-context';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import { useAnnotationsDispatch } from '@confluence/annotation-provider-store';

import type { ViewComponentProps } from './ViewComponent';

type ViewManagerProps = ViewComponentProps & {
	setViewProps: (viewProps: (InlineCommentViewComponentProps & ViewComponentProps) | null) => void;
};

export const ViewManagerWithProps = ({
	pageId,
	updateDocument,
	setViewProps,
	isArchived,
	annotations,
	clickElementTarget,
	deleteAnnotation,
	getInlineNodeTypes,
}: ViewManagerProps & InlineCommentViewComponentProps) => {
	const { hasContentChanged } = useCommentsContentState();
	const { resetContentChanged } = useCommentsContentActions();
	const { showModal } = useDialogs();
	const { handleClose: handleSelectionClose } = useAnnotationsDispatch();

	useEffect(() => {
		const wasHighlightClicked = Boolean(annotations.length && clickElementTarget);

		const handleSetViewProps = () => {
			// Below logic has been added to prevent the nested renderers like
			// excerpt/page properties macros to not set the wrong value of isArchived.
			// Thus, we need to setViewProps only when the "PageStatusQuery" defined in ViewPageComponent resolves

			if (isArchived !== undefined) {
				setViewProps({
					pageId,
					updateDocument,
					isArchived,
					annotations,
					clickElementTarget,
					deleteAnnotation,
					wasHighlightClicked,
					getInlineNodeTypes,
				});
			}
		};

		if (hasContentChanged && wasHighlightClicked) {
			showModal(CommentWarningDialog, {
				onConfirm: () => {
					resetContentChanged();
					handleSelectionClose();
					handleSetViewProps();
				},
			});
		} else {
			// Only close the selection component if a highlight was clicked
			if (wasHighlightClicked) {
				handleSelectionClose();
			}
			handleSetViewProps();
		}

		// We only want to listen to changes that signifies a new comment click
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [annotations, clickElementTarget]);

	return null;
};

export const ViewManager = ({
	pageId,
	updateDocument,
	setViewProps,
	isArchived,
}: ViewManagerProps) =>
	memo((viewComponentProps: InlineCommentViewComponentProps) => (
		<ViewManagerWithProps
			pageId={pageId}
			updateDocument={updateDocument}
			setViewProps={setViewProps}
			isArchived={isArchived}
			{...viewComponentProps}
		/>
	));

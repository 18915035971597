import { useExtensionList, FORGE_MODULE_XEN_MACRO } from '@confluence/forge-ui';
import { fg } from '@confluence/feature-gating';

// Hook to retrieve forge app viewport sizes for initial SSR render
export const useExtensionViewportSizes = () => {
	const { extensions, loading, error } = useExtensionList({
		moduleType: FORGE_MODULE_XEN_MACRO,
		skipQuery: !fg('confluence_preload_forge_viewport_heights'),
	});

	if (loading || error || !extensions) {
		return [];
	}

	return extensions
		.map((extension) =>
			extension.properties?.viewportSize
				? { extensionId: extension.id, viewportSize: extension.properties.viewportSize }
				: {},
		)
		.filter((item) => Object.keys(item).length > 0);
};

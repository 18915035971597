import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

import { filterCommonAttributes } from '../../ai-answer-dialog/analytics-helpers';
import { type commonAttributesType } from '../../ai-answer-dialog/types';

import {
	AutoHighlightsProduct,
	KeyPhraseCategory,
	type KeyPhraseNodeType,
} from './api/useAutohighlightSupplier';
import { getHighlightCategories } from './common/utils';

export const onCuratedDefinitionUpdateButtonClicked = ({
	commonAttributes,
	newDefinition,
	restrictedScope,
	sourceSelected,
	aiDefinitionEdited,
}: {
	commonAttributes: commonAttributesType;
	newDefinition: string;
	restrictedScope: string;
	sourceSelected: boolean;
	aiDefinitionEdited: boolean;
}): AnalyticsEventPayload => {
	const {
		query: keyPhrase,
		answerString: oldDefinition,
		extraAttributes,
		source,
	} = commonAttributes;
	const { contentId, readingAidsSessionId } = extraAttributes || {};
	return {
		action: 'clicked',
		actionSubject: 'aiCuratedDefinitionUpdateButton',
		source,
		eventType: 'ui',
		attributes: {
			contentId,
			readingAidsSessionId,
			restrictedScope,
			sourceSelected,
			newDefinitionLength: newDefinition.length,
			// whether the user is editing an AI answer or already edited definition
			aiDefinitionEdited,
		},
		nonPrivacySafeAttributes: {
			keyPhrase,
			oldDefinition,
			newDefinition,
		},
	};
};

export const onCuratedDefinitionSeeEditHistoryButtonClicked = (
	props: commonAttributesType,
): AnalyticsEventPayload => {
	const { query, answerString, extraAttributes, source } = props;
	const { contentId, readingAidsSessionId } = extraAttributes || {};

	return {
		action: 'clicked',
		actionSubject: 'aiCuratedDefinitionSeeEditHistoryButton',
		source,
		eventType: 'ui',
		attributes: {
			contentId,
			readingAidsSessionId,
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

export const onLikeButtonClick = (props: commonAttributesType): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerLikeButton',
		source: source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

export const onDislikeButtonClick = (props: commonAttributesType): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerDislikeButton',
		source: source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

export const onShownAutoDefinitionHighlights = ({
	product,
	contentId,
	keyPhrases,
	fieldType,
}: {
	product: AutoHighlightsProduct;
	contentId: string;
	keyPhrases: KeyPhraseNodeType[];
	fieldType?: string;
}): AnalyticsEventPayload => ({
	action: 'shown',
	actionSubject: 'aiDefinitionsAutohighlights',
	source: 'viewPageScreen',
	eventType: 'track',
	attributes: {
		product,
		contentId,
		numHighlights: keyPhrases.length,
		categories: getHighlightCategories(keyPhrases),
		beAcronymsEnabled: true,
		fieldType,
	},
});

export const onClickAutoDefinitionHighlights = ({
	contentId,
	sessionId,
	keyPhraseCategory,
	fieldType,
}: {
	contentId: string;
	sessionId: string;
	keyPhraseCategory: KeyPhraseCategory | undefined;
	fieldType?: string;
}): AnalyticsEventPayload => ({
	action: 'clicked',
	actionSubject: 'aiDefinitionsAutohighlights',
	source: 'viewPageScreen',
	eventType: 'ui',
	attributes: {
		contentId,
		readingAidsSessionId: sessionId,
		keyPhraseCategory: keyPhraseCategory || KeyPhraseCategory.AUTO,
		fieldType,
	},
});

export const onClickDisableDefinition = (props: commonAttributesType): AnalyticsEventPayload => ({
	action: 'clicked',
	actionSubject: 'aiDefinitionsDisableDefinition',
	source: 'viewPageScreen',
	eventType: 'ui',
	attributes: {
		...filterCommonAttributes(props),
	},
});
